import React from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Card, Button, ButtonToolbar } from "react-bootstrap";
import './Internationaltour.css';
import { Form } from "react-bootstrap";
import Dubai from '../Images/dubai.jpg';
import Maldives from '../Images/maldives.jpg';
import Thailand from '../Images/thailand.jpg';
import Greece from '../Images/greece.jpg';
import { HashLink as Link } from 'react-router-hash-link';

function Internationaltour() {

    const heading = {
        color: "#000",
        fontWeight: "bold",
        textDecoration: 'underline',
    }
    return (
        <div className="custom-cons text-center" id="packages">
            <div className="text-left mt-3">
                <h2 style={heading}>International Tour Packages  !</h2>
            </div>
            <div className="mt-5 p-5 cards-con">
                <Row>
                    <Col className="col11">
                        <Card className="main-card" style={{ width: '18rem' }}>
                            <Card.Img className="imageStyles" variant="top" src={Dubai} />
                            <Card.Body>
                                <Card.Title className="mt-3">Dubai Tour</Card.Title>
                                <Link to="#contact"><button className="cards-buttons mt-3 mb-4">Book</button></Link>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col className="col22">
                        <Card className="main-card" style={{ width: '18rem' }}>
                            <Card.Img className="imageStyles" variant="top" src={Maldives} />
                            <Card.Body>
                                <Card.Title className="mt-3">Maldives Tour</Card.Title>
                                <Link to="#contact"><button className="cards-buttons mt-3 mb-4">Book</button></Link>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col className="col33">
                        <Card className="main-card" style={{ width: '18rem' }}>
                            <Card.Img className="imageStyles" variant="top" src={Thailand} />
                            <Card.Body>
                                <Card.Title className="mt-3">Thailand Tour</Card.Title>
                                <Link to="#contact"><button className="cards-buttons mt-3 mb-4">Book</button></Link>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col className="col44">
                        <Card className="main-card" style={{ width: '18rem' }}>
                            <Card.Img className="imageStyles" variant="top" src={Greece} />
                            <Card.Body>
                                <Card.Title className="mt-3">Greece Tour</Card.Title>
                                <Link to="#contact"><button className="cards-buttons mt-3 mb-4">Book</button></Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>

    );
}

export default Internationaltour;