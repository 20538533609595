import Carousel from 'react-bootstrap/Carousel';
import BannerImage1 from '../Images/banner.jpg';
import BannerImage2 from '../Images/banner2.jpg';
import BannerImage3 from '../Images/banner3.jpg';
import './Banner.css';
import { HashLink as Link } from 'react-router-hash-link';

function Banner() {
  
  const imageStyle = {
    height: '550px', // Adjust the desired height here
  };

  const banner2={
    color: '#fff',
  }
  
  return (
    <section id="home">
    <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={BannerImage1}
          alt="First slide"
          style={imageStyle}
        />
        <Carousel.Caption>
        <h2 style={{fontWeight: 'bold'}}>Turning Dreams into Destinations</h2>
        <Link to="#contact"><button className='button'>Get Quotations</button></Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={BannerImage2}
          alt="Second slide"
          style={imageStyle}
        />
        <Carousel.Caption>
        <h2 style={{ color: '#fff', fontWeight: 'bold'}}>Turning Dreams into Destinations</h2>
        <Link to="#contact"><button className='button'>Get Quotations</button></Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={BannerImage3}
          alt="Third slide"
          style={imageStyle}
        />
        <Carousel.Caption>
        <h2 style={{fontWeight: 'bold'}}>Turning Dreams into Destinations</h2>
        <Link to="#contact"><button className='button'>Get Quotations</button></Link>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </section>
  );
}

export default Banner;