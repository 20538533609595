import React from 'react';
import './Services.css';
import {Container} from 'react-bootstrap';
import Service from '../Images/services.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

function Services() {
    

  return (
    <section id="about">
    <div className='main-con'>
        
            <div className='div1'>
                <h1>Our Services</h1> <br />
                <p>We are committed to give maximum possible quality services to the customers so that they will feel value for money and most importantly, a memorable trip.</p>
                <p className='mt-5'> <FontAwesomeIcon icon={faCheck} className='fonts'/>Best Quality of Service</p>
                <p className='mt-3'><FontAwesomeIcon icon={faCheck} className='fonts'/>Hotel Reservations</p>
                <p className='mt-3'><FontAwesomeIcon icon={faCheck} className='fonts'/>No Hidden Charges</p>
                <p className='mt-3'><FontAwesomeIcon icon={faCheck} className='fonts'/>Professional Drivers</p>
                <p className='mt-3'><FontAwesomeIcon icon={faCheck} className='fonts'/>Sanitised Rides</p>
            </div>

            <div className='div2'>
                <img className='service-image' src={Service} />
            </div>
    </div>
    </section>
  );
}

export default Services;