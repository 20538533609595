import React from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Card, Button } from "react-bootstrap";
import './Offering.css';
import Card1 from '../Images/card1.jpg';
import Card2 from '../Images/card2.jpg';
import Card3 from '../Images/card3.jpg';
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

function Offering() {


    const heading={
        color: "#f08700",
        fontWeight: "bold",
        textDecoration: 'underline',
        marginTop: '15px'
    }
    return (
        <div className="custom-con">
            <div className="text-left">
                <h1 style={heading}>What We Offer  !</h1>
            </div>
            <Container className="mt-5 p-5 container-col">
                <Row className="rows">
                    <Col className="col1">
                        <Card style={{ width: '18rem', border: 'none', backgroundColor: 'transparent' }}>
                            <Card.Img className="imageStyle"  variant="top" src={Card1} />
                            <Card.Body className="mt-3">
                                <Card.Title className="text-center cards-title">Travel & Tourism</Card.Title>
                            </Card.Body>
                        </Card>
                        <Link to="#contact"><button className="card-buttons mt-4">Book Now</button></Link>
                    </Col>

                    <Col className="col2">
                        <Card style={{ width: '18rem', border: 'none', backgroundColor: 'transparent' }}>
                            <Card.Img className="imageStyle"  variant="top" src={Card2} />
                            <Card.Body className="mt-3">
                                <Card.Title className="text-center cards-title">Hotel Booking</Card.Title>
                            </Card.Body>
                        </Card>
                        <Link to="#contact"><button className="card-buttons mt-4">Book Now</button></Link>
                    </Col>

                    <Col className="col3">
                        <Card style={{ width: '18rem', border: 'none', backgroundColor: 'transparent' }}>
                            <Card.Img className="imageStyle" variant="top" src={Card3} />
                            <Card.Body className="mt-3">
                                <Card.Title className="text-center cards-title">Flights</Card.Title>
                            </Card.Body>
                        </Card>
                        <Link to="#contact"><button className="card-buttons mt-4">Book Now</button></Link>
                    </Col>
                </Row>
            </Container>
        </div>

    );
}

export default Offering;