import './App.css';
import Navigation from './Component/Navigation';
import Banner from './Component/Banner';
import Offering from './Component/Offering';
import Internationaltour from './Component/Internationaltour';
import Services from './Component/Services';
import Contact from './Component/Contactus';
import Footer from './Component/Footer';
import { BrowserRouter } from 'react-router-dom';
import {HashLink as Link} from 'react-router-hash-link';

function App() {
  return (
    <BrowserRouter>
    <div >
    
      <Navigation/>
      
      <Banner />
      <Offering />
      
      <Internationaltour id="packages" />
      <Services id="about"/>
      <Contact id="contact"/>
      <Footer />
    </div>
    </BrowserRouter>
  );
}

export default App;
