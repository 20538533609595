import Container from 'react-bootstrap/Container';
import { Nav, Navbar, NavbarText } from 'react-bootstrap';
import Logo from '../Images/logo.png'
import './Navigation.css';
import { Link as ScrollLink } from 'react-scroll';
import { HashLink as Link } from 'react-router-hash-link';


function Navigation() {
  const contact = "+13322649584";
  return (
    
    <Navbar expand="lg" className="custom-nav" fixed='top'>
      <Container>
        <Navbar.Brand className='logo' href="#"><img src={Logo} width={80} height={60}></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 m-5"
            style={{ maxHeight: '100px'}}
            navbarScroll
          >
            
            <Link className='nav-links' to='#home'  >Home</Link>
            
            <Link className='nav-links' to="#about">About Us</Link>
            <Link className='nav-links' to='#packages'>Packages</Link>
            <Link className='nav-links' to='#contact'>Contact Us</Link>
          </Nav>
          <NavbarText className='contact-text'>Need help? Call Us Now : <span className='custom-contact'>{contact}</span></NavbarText>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;