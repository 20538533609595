import React, {useState, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './Contactus.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import emailjs, { send } from '@emailjs/browser';

function Contact() {

    const form = useRef();


    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_n9qxmje', 'template_9dcltyr', form.current, 'fDgvasp5pVH75aohd')
        .then((result) => {
            console.log(result.text);
            alert("Your message successfully sent !");
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <section id="contact">
        <div className='main-container'>
            <div className='div11'>
                <h2>Contact Us</h2> <br />
                <p className='mt-2'>Need to get in touch with us ? Please feel free to reach out to us at any time with your questions or concerns. Our dedicated team is here to assist you. You can contact us via email, phone, or through the contact form on our website. We look forward to hearing from you and providing excellent service.</p>
            </div>

            <div className='div22'>
                <Form ref={form} onSubmit={sendEmail}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Full Name*</Form.Label>
                        <Form.Control className='input-form' name="user_name"  type="text" />
                        <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Email</Form.Label>
                        <Form.Control className='input-form' name="user_email" type="email" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>What can we help you with ?</Form.Label>
                        <Form.Control className='input-form' as="textarea" name ="message" rows={2} />
                        
                    </Form.Group>
                    <button onClick={sendEmail} className='form-button' >Submit</button>
                </Form>
            </div>
        </div>
        </section>
    )
}

export default Contact;